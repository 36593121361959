import React from 'react';
import Arrow from 'src/icons/Arrow';
import Link from 'src/components/Link';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from 'src/styles/theme';

const LinkWrapper = styled('div')`
    background-color: ${theme.color.white};

    ${theme.spacing.mobile._12('padding')};
    ${theme.spacing.mobile._40('padding-top')};
    ${theme.spacing.mobile._40('padding-bottom')};

    ${theme.spacing.tablet._20('padding')};
    ${theme.spacing.tablet._40('padding-top')};
    ${theme.spacing.tablet._40('padding-bottom')};

    ${theme.spacing.desktop._32('padding')};
    ${theme.spacing.desktop._88('padding-top')};
    ${theme.spacing.desktop._88('padding-bottom')};
`;

const LinkList = styled('div')`
    border-top: 1px solid ${theme.color.black};
`;

const LinkListItem = styled(Link)`
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid ${theme.color.black};
    color: ${theme.color.black};
    display: flex;
    width: 100%;

    ${theme.spacing.mobile._12('padding-top')};
    ${theme.spacing.mobile._12('padding-bottom')};

    ${theme.spacing.tablet._12('padding-top')};
    ${theme.spacing.tablet._12('padding-bottom')};


    span {
        font-family: "SuisseIntL";
        font-weight: 400;
        flex: 1;
        line-height: 150%;

        ${theme.fontSize.sans.mobile._20};
        ${theme.fontSize.sans.tablet._20};
        ${theme.fontSize.sans.desktop._24};
    }

    > svg {
        transition: transform 0.2s;
    }

    &:hover {
        > svg {
            transform: translate3d(10px, 0, 0)
        }
    }
`;

const LinkModule = ({data}) => {
    const {
        links
    } = data;

    return (
        <LinkWrapper>
            <LinkList>
                {links.map((link, index) => {
                    const linkData = link.link;
                    return (
                        (!!linkData.url && !!linkData.text &&
                            <LinkListItem key={index} src={linkData.url}><span>{linkData.text}</span><Arrow /></LinkListItem>
                        )
                    );
                })}
            </LinkList>
        </LinkWrapper>
    );
};

LinkModule.propTypes = {
    data: PropTypes.shape({
        links: PropTypes.arrayOf(
            PropTypes.shape({
                link: PropTypes.shape({
                    text: PropTypes.string,
                    url: PropTypes.string
                })
            })
        )
    }).isRequired,
};

export default LinkModule;
